// @flow

import * as React from 'react'
import Obfuscate from 'react-obfuscate'
import Analytics from '../../utils/analytics'
import ContactForm from '../ContactForm'
import { Container, Title, Subtitle, HR, Email } from './style'

type Props = {
  title: string,
  subtitle: string,
  buttonLabel: string,
}

const ContactFormWithEmailLink = ({ title, subtitle, buttonLabel }: Props) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <ContactForm submitButtonLabel={buttonLabel} />
    <HR />
    <Email>
      <p>Or just send an email to</p>
      <p>
        <Obfuscate
          email="info@gothamstudios.com"
          onClick={() => {
            Analytics.event({
              category: 'Outbound Navigation',
              action: 'Click',
              label: 'Contact Form: Email Address',
            })
          }}
        />
      </p>
    </Email>
  </Container>
)

export default ContactFormWithEmailLink
