// @flow

import styled from '@emotion/styled'
import { sizes } from '../../styles/dimensions'

export const Container = styled.div`
  position: relative;

  ${props =>
    props.devices.includes('phone')
      ? `
    @media (max-width: ${sizes.tablet}) {
      display: block;
    }
  `
      : `
    @media (max-width: ${sizes.tablet}) {
      display: none;
    }
  `};

  ${props =>
    props.devices.includes('tablet')
      ? `
    @media (min-width: ${sizes.tablet}) and (max-width: ${sizes.desktop}) {
      display: block;
    }
  `
      : `
      @media (min-width: ${sizes.tablet}) and (max-width: ${sizes.desktop}) {
      display: none;
    }
  `};

  ${props =>
    props.devices.includes('desktop')
      ? `
      @media (min-width: ${sizes.desktop}) and (max-width: ${sizes.desktopHD}) {
      display: block;
    }
  `
      : `
      @media (min-width: ${sizes.desktop}) and (max-width: ${sizes.desktopHD}) {
      display: none;
    }
  `};

  ${props =>
    props.devices.includes('desktopHD')
      ? `
    @media (min-width: ${sizes.desktopHD}) {
      display: block;
    }
  `
      : `
    @media (min-width: ${sizes.desktopHD}) {
      display: none;
    }
  `};
`
