// @flow

import styled from '@emotion/styled'
import { media } from '../../styles/dimensions'
import * as Fonts from '../../styles/fonts'
import Colors from '../../styles/colors'

export const Container = styled.div`
  padding: 72px 24px;

  ${media.desktop`
    width: 752px;
    margin: 0 auto;
    padding: 96px 0;
  `};
`

export const Title = styled.h2`
  ${Fonts.Title};
  margin: 0 auto 32px;
  text-align: center;
  max-width: 480px;
`

export const Subtitle = styled.h3`
  ${Fonts.Subtitle};
  margin: 0 auto 56px;
  text-align: center;
  max-width: 480px;
`

export const HR = styled.div`
  background: ${Colors.grey6};
  width: 208px;
  height: 1px;
  margin: 32px auto;
`

export const Email = styled.div`
  text-align: center;

  p:first-of-type {
    ${Fonts.Body2};
    margin-bottom: 16px;
  }

  p:last-of-type {
    ${Fonts.Subtitle};

    a {
      ${Fonts.Link};
      ${Fonts.Subtitle};
    }
  }
`
