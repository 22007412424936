// @flow

import * as React from 'react'
import { Container } from './style'
import { media } from '../../styles/dimensions'

type Props = {
  children: React.Node,
  devices?: Array<$Keys<typeof media>>,
  condition?: boolean,
}

const RenderIf = ({ children, devices, condition }: Props) => {
  if (devices && devices.length) {
    return <Container devices={devices}>{children}</Container>
  }

  if (condition) {
    return <>{children}</>
  }

  return null
}

RenderIf.defaultProps = {
  devices: [],
  condition: true,
}

export default RenderIf
